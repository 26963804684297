export const TYPE_ERRORS = {
  ERROR_CLIENT_EXIST: 'ERROR_CLIENT_EXIST',
  USER_OR_PASSWORD_INVALID: 'USER_OR_PASSWORD_INVALID',
  USER_OR_EMAIL_INVALID: 'USER_OR_EMAIL_INVALID',
  USER_INACTIVE: 'USER_INACTIVE',
  ERROR_REQUEST: 'ERROR_REQUEST',
  ACCESS_DENIED: 'ACCESS_DENIED',
  PASSWORD_NOT_MATCH: 'PASSWORD_NOT_MATCH',
  ERROR_NOT_DELETE_DEFAULT: 'ERROR_NOT_DELETE_DEFAULT',
  GENERIC_ERROR: 'GENERIC_ERROR',
  PASSWORD_NOT_VALID: 'PASSWORD_NOT_VALID',
  // Workflow
  ERROR_NOT_UPDATE_ACTION_DEFAULT: 'ERROR_NOT_UPDATE_ACTION_DEFAULT',
  ERROR_NOT_DELETE_BLOCKED: 'ERROR_NOT_DELETE_BLOCKED',
  // COMPANIES
  ERROR_COMPANY_NOT_DELETE_DEFAULT: 'ERROR_COMPANY_NOT_DELETE_DEFAULT',
  // FILES
  ERRRO_FILE_MAX_SIZE_EXCEEDED: 'ERRRO_FILE_MAX_SIZE_EXCEEDED',
  // UNAUTHORIZED
  UNAUTHORIZED: 'UNAUTHORIZED',
  ERROR_NOT_ALLOWED: 'ERROR_NOT_ALLOWED',
  // 2FA
  TWO_FACTOR_AUTHENTICATION_CODE_INVALID: 'TWO_FACTOR_AUTHENTICATION_CODE_INVALID'
} as const

export const CODE_ERRORS = {
  // CLIENTS
  ERROR_CLIENT_EXIST: 'El cliente ya existe en el sistema con el dato de identificación proporcionado. Sugerimos realizar una búsqueda en el sistema para encontrar el cliente existente o verificar la información ingresada.',
  USER_OR_PASSWORD_INVALID: 'Usuario o contraseña incorrectos',
  USER_OR_EMAIL_INVALID: 'Usuario o correo electrónico incorrectos',
  USER_INACTIVE: 'Usuario inactivo',
  NOT_FOUND: 'No se encontró el recurso solicitado',
  ERROR_REQUEST: 'Ocurrió un error al realizar la solicitud, intente nuevamente más tarde',
  ACCESS_DENIED: 'Error de acceso',
  PASSWORD_NOT_MATCH: 'Las contraseñas no coinciden',
  ERROR_NOT_DELETE_DEFAULT: 'No se puede eliminar un registro por defecto, asegurese de asignar a otro registro como por defecto',
  // GENERICS
  GENERIC_ERROR: 'Ocurrió un error, intente nuevamente más tarde',
  LOGIN_ERROR: 'Ocurrió un error al iniciar sesión, intente nuevamente más tarde',
  // Workflow
  ERROR_NOT_UPDATE_ACTION_DEFAULT: 'No se puede cambiar la acción de un registro por defecto, asegurese de asignar a otro registro como por defecto',
  ERROR_NOT_DELETE_BLOCKED: 'No se puede eliminar un registro bloqueado',
  // COMPANIES
  ERROR_COMPANY_NOT_DELETE_DEFAULT: 'No se puede eliminar una empresa por defecto, asegurese de asignar a otra empresa como por defecto',
  // FILES
  ERRRO_FILE_MAX_SIZE_EXCEEDED: 'El archivo excede el tamaño máximo permitido',
  // UNAUTHORIZED
  UNAUTHORIZED: 'No autorizado',
  ERROR_NOT_ALLOWED: 'No tienes los permisos necesarios para realizar esta acción',
  // 2FA
  TWO_FACTOR_AUTHENTICATION_CODE_INVALID: 'Código de autenticación inválido'
} as const
