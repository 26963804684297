import useAuth from '../hooks/use-auth'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { joinPath } from '@/utils'
import { PRIVATE_ROUTE, PUBLIC_ROUTE } from '@/router/paths'

import { Form as FormHouse, Field as FieldHouse } from 'houseform'
import { type IUserCredentials } from '@/features/users/models/user'
import { FormField } from '@/components/form/form-field'
import { FormLabel } from '@/components/form/form-label'
import { FormInput } from '@/components/form/form-input'
import { FormMessages } from '@/components/form/form-messages'
import { Button } from '@/components/ui/button'
import { Loader } from 'lucide-react'
import { signIn } from '../auth.service'
import LayoutAuth from '../components/layout-auth'
import { AUTH_SCHEMA, AUTH_SCHEMA_VALIDATION } from '../auth.schema'
import { ROUTES_AUTH } from '../router/auth-router'
import { dataProperties } from '@/config'

const Login = () => {
  const navigate = useNavigate()

  const { setAuth, auth } = useAuth()
  const [loading, setLoading] = useState(false)

  const onSignIn = async (values: IUserCredentials) => {
    setLoading(true)
    const { user, error, authTwoFactor } = await signIn(values)

    if (user === null) {
      if (authTwoFactor) {
        setLoading(false)
        navigate(joinPath('/', PUBLIC_ROUTE.AUTH, ROUTES_AUTH.TWO_FACTOR), { replace: true })
        return
      }

      setLoading(false)
      toast.error(error)
      return
    }

    setAuth({
      username: user.username,
      _id: user[dataProperties.id],
      id: user[dataProperties.uid],
      accessToken: user.accessToken,
      permissions: user.permissions,
      permissionsStates: user.permissionsStates,
      typeAccount: user.typeAccount
    })

    // const persist = values?.persist ?? false
    // setPersist(persist)
    // localStorage.setItem('persist', JSON.stringify(persist))

    navigate(joinPath('/', PRIVATE_ROUTE.ADMIN), { replace: true })
    setLoading(false)
  }

  if (auth?.id) { navigate(`/${PRIVATE_ROUTE.ADMIN}`, { replace: true }) }

  return (
    <LayoutAuth
      title='¡Bienvenido!'
      description={<>Inicie sesión en tu cuenta de <strong>IDC Forms - Valores</strong></>}
    >
      <FormHouse
        onSubmit={onSignIn}
      >
        {
          ({ submit }) => (
            <form
              onSubmit={(e) => {
                e.preventDefault()
                submit()
              }}
              className='grid grid-cols-1 w-full gap-6'
            >
              <FieldHouse
                name={AUTH_SCHEMA.USERNAME}
                onBlurValidate={AUTH_SCHEMA_VALIDATION.username}
                onSubmitTransform={(value: string) => value.toLowerCase()}
              >
                {
                  ({ value, setValue, errors, onBlur }) => (
                    <FormField required messages={errors.map(e => e)}>
                      <FormLabel>Usuario</FormLabel>
                      <FormInput className='lowercase' type='text' autoComplete='username' value={value} onChange={e => { setValue(e.target.value) }} onBlur={onBlur} />
                      <FormMessages />
                    </FormField>
                  )
                }
              </FieldHouse>
              <FieldHouse
                name={AUTH_SCHEMA.PASSWORD}
                onBlurValidate={AUTH_SCHEMA_VALIDATION.password}
                onSubmitTransform={(value: string) => value.trim()}
              >
                {
                  ({ value, setValue, errors, onBlur }) => (
                    <FormField required messages={errors.map(e => e)}>
                      <FormLabel>Contraseña</FormLabel>
                      <FormInput className='lowercase' type='password' autoComplete='current-password' value={value} onChange={e => { setValue(e.target.value) }} onBlur={onBlur} />
                      <FormMessages />
                    </FormField>
                  )
                }
              </FieldHouse>
              {/* <FieldHouse
                name={AUTH_SCHEMA.PERSIST}
                initialValue={false}
              >
                {
                  ({ value, setValue }) => (
                    <label htmlFor={AUTH_SCHEMA.PERSIST} className='flex items-center space-x-2'>
                      <Checkbox checked={value} onCheckedChange={(checked) => { setValue(!!checked) }} id={AUTH_SCHEMA.PERSIST} />
                      <span className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70" >Confiar en este dispositivo</span>
                    </label>
                  )
                }
              </FieldHouse> */}
              <Button type='submit'>
                {
                  loading
                    ? (<><Loader className="mr-2 h-4 w-4 animate-spin" />Ingresando...</>)
                    : 'Iniciar sesión'
                }
              </Button>
            </form>
          )
        }
      </FormHouse>
      <Button variant={'link'} asChild><Link to={joinPath('/', PUBLIC_ROUTE.AUTH, ROUTES_AUTH.FORGOT_PASSWORD)}>¿Olvidaste tu contraseña?</Link></Button>
    </LayoutAuth>
  )
}

export default Login
